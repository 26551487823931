<template>
  <v-card
    tile
    v-touch="{
      right: () => swipe(),
    }"
  >
    <div class="object__photo">
      <button
        class="btn-reset close__card"
        @click="this.$emit('update:modal', false)"
      >
        <svg
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1_1545)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.3125 12.0184C21.3125 12.2546 21.2187 12.4812 21.0516 12.6482C20.8846 12.8152 20.6581 12.909 20.4218 12.909L7.78879 12.909L12.7266 17.6115C12.8886 17.7771 12.9797 17.9992 12.9806 18.2309C12.9815 18.4625 12.8921 18.6853 12.7315 18.8521C12.5708 19.019 12.3515 19.1167 12.12 19.1245C11.8885 19.1323 11.6631 19.0497 11.4915 18.894L4.96006 12.6597C4.87386 12.5766 4.8053 12.477 4.75847 12.3668C4.71164 12.2566 4.6875 12.1381 4.6875 12.0184C4.6875 11.8987 4.71164 11.7802 4.75847 11.67C4.8053 11.5599 4.87386 11.4602 4.96006 11.3772L11.4915 5.14279C11.5749 5.05759 11.6745 4.98999 11.7845 4.94399C11.8944 4.89799 12.0125 4.87454 12.1317 4.87501C12.2509 4.87548 12.3688 4.89987 12.4784 4.94674C12.588 4.9936 12.6871 5.06199 12.7697 5.14785C12.8524 5.23371 12.917 5.33529 12.9597 5.44657C13.0024 5.55785 13.0224 5.67657 13.0183 5.7957C13.0143 5.91483 12.9864 6.03193 12.9363 6.14008C12.8862 6.24823 12.8149 6.34521 12.7266 6.42529L7.78879 11.1278L20.4218 11.1278C20.6581 11.1278 20.8846 11.2216 21.0516 11.3887C21.2187 11.5557 21.3125 11.7822 21.3125 12.0184Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1_1545"
              x="0.6875"
              y="0.875"
              width="24.625"
              height="22.25"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_1545"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_1545"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </button>
      <v-carousel :show-arrows="false" hide-delimiter-background height="420">
        <v-carousel-item
          v-for="(photo, index) in item.photos"
          :key="index"
          :src="photo"
          cover
        ></v-carousel-item>
      </v-carousel>
    </div>
    <div class="object__card flex">
      <div class="object__status__block mb-5 flex">
        <div class="object__status">{{ item.status }}</div>
        <div class="object__type">
          {{ item.type }}
        </div>
      </div>
      <div class="object__price__block mb-5 flex">
        <div class="object__price">
          {{ item.currency }} {{ item.fullPrice }}
        </div>
        <div class="object__price__square gray-text">
          {{ item.squarePrice }} за м²
        </div>
      </div>
      <div class="object__title__block mb-5">
        <h2>{{ item.lot_name }}</h2>
        <p class="gray-text">{{ item.lot_adress }}</p>
      </div>
      <div class="characters__block mb-5">
        <div class="divider"></div>
        <div class="characters flex">
          <div class="flex column">
            <div class="bold-text text-center">{{ item.lot_square }} м²</div>
            <div class="gray-text text-center">площадь</div>
          </div>
          <div class="flex column">
            <div class="bold-text text-center">
              {{ item.lot_floor.split(":")[0] }}/{{
                item.lot_floor.split(":")[1].trim()
              }}
            </div>
            <div class="gray-text text-center">этаж</div>
          </div>
          <div class="flex column">
            <div class="bold-text text-center">
              {{ item.lot_otc != "Без отделки" ? "Есть" : "Нет" }}
            </div>
            <div class="gray-text text-center">отделка</div>
          </div>
          <div class="flex column">
            <div class="bold-text text-center">
              {{ item.status == "Вторичка" ? "Да" : "Нет" }}
            </div>
            <div class="gray-text text-center">вторичка</div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="actions__block flex column">
        <v-btn
          class="rounded-xl"
          color="#ff8351"
          elevation="0"
          min-height="50px"
          width="100%"
          :loading="loading"
          @click="createPresentation('enable')"
          >Создать PDF</v-btn
        >
        <!-- <v-btn
          :disabled="item.selected"
          class="rounded-xl mb-2"
          :color="item.selected ? '#ff8351' : '#F4F6F9'"
          elevation="0"
          height="100%"
          width="100%"
          @click="selected"
          >Добавить в подборку</v-btn
        >-->
      </div>
      <div class="object__discription__block mb-5">
        <div class="extra__block flex column mb-5">
          <v-row class="comission">
            <v-col class="comission__title gray-text" cols="6">Комиссия</v-col>
            <v-col class="comission__value bold-text" cols="6">{{
              item.comission
            }}</v-col>
          </v-row>
          <v-row class="comission">
            <v-col class="comission__title gray-text" cols="6"
              >Актуализировано</v-col
            >
            <v-col class="comission__value bold-text" cols="6">{{
              item.lot_date_update
            }}</v-col>
          </v-row>
          <v-row class="comission">
            <v-col class="comission__title gray-text" cols="6"
              >Тип сделки</v-col
            >
            <v-col class="comission__value bold-text" cols="6">{{
              item.type
            }}</v-col>
          </v-row>
          <!-- <v-row class="comission">
            <v-col class="comission__title gray-text" cols="6">ID</v-col>
            <v-col class="comission__value bold-text" cols="6">{{
              item.id
            }}</v-col>
          </v-row>-->
        </div>
        <h2 class="mb-4">Описание</h2>
        <div class="discriptions">{{ item.lot_description }}</div>
      </div>
      <!-- <div class="assigned_block mb-5">
        <h2 class="mb-5">Ответственный</h2>
        <div class="assigned flex">
          <div class="assigned__avatar mr-5">
            <v-avatar :image="item.assigned.avatar" size="60"></v-avatar>
          </div>
          <div class="assigned__name__block flex column">
            <div class="gray-text">{{ item.assigned.position }}</div>
            <div class="assigned__name bold-text">{{ item.assigned.name }}</div>
          </div>
        </div>
      </div>-->
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "Card-Wiev",
  props: {
    item: Object,
    modal: Boolean,
    select: Boolean,
  },
  emits: ["update:modal", "update:select"],
  data() {
    return {
      loading: false,
      direction: "",
      items: [],
    };
  },
  methods: {
    async createPresentation(type) {
      try {
        this.items = [this.item];
        // console.log(this.items);
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          item.currencyArr = ["RUB", item.currency];
          for (const index in item) {
            formData.append(`cards[${i}][${index}]`, item[index]);
          }
          formData.append(`cards[${i}][presType]`, type);
        }

        const res = await axios.post(
          "https://lissearch.ru/backend/presentation/create_presentation.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const name = `${this.item.name}-${this.item.square} m2.pdf`;
        const link = document.createElement("a");
        link.href = res.data;
        link.download = name;
        this.loading = false;
        link.click();
      } catch (e) {
        console.log(e);
      }
    },
    swipe() {
      this.$emit("update:modal", false);
    },
    selected() {
      this.$emit("update:select", true);
    },
  },
};
</script>

<style scoped>
.close__card {
  z-index: 10;
  position: absolute;
  top: 20px;
  left: 20px;
}
.object__card {
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border-radius: 35px;
  position: absolute;
  top: 43%;
}
.object__photo {
  position: relative;
}
.object__type {
  padding: 0 4px;
  border: 1px solid #ff8351;
  border-radius: 6px;
  background-color: #ff8351;
  color: #fff;
}
.object__status {
  padding: 0 4px;
  margin-right: 15px;
  border: 1px solid #f4f6f9;
  border-radius: 6px;
  background-color: #f4f6f9;
}
.object__price {
  margin-right: 15px;
  font-size: 26px;
  font-weight: 700;
}
.object__price__square {
  font-size: 16px;
}
.characters {
  justify-content: space-between;
  padding: 20px;
}
.comission {
  justify-content: space-between;
}
.actions__block {
  margin-bottom: 20px;
}
</style>
